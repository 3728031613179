@media screen {
	.newmodal {
		&-container {
			position: fixed;
			background: rgba(0, 0, 0, 0.7);
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			padding: 2.5 * $extraspacing 0.5 * $extraspacing;
			z-index: 500;

			@media only screen and (max-width: 768px) {
				padding: 0;
			}
			height: auto;
			overflow-y: auto;
		}

		margin: 0 auto;
		min-width: 40%;
		max-width: 75%;
		color: $black;
		background: $white;
		border-radius: $standardspacing;
		text-align: left;

		height: auto;
		overflow: hidden;

		&.fullwidth {
			min-width: 95%;
			max-width: 95%;

			@media only screen and (max-width: 768px) {
				min-width: 100%;
				max-width: 100%;
				border-radius: 0;
			}
		}

		@media only screen and (max-width: 576px) {
			min-width: 100%;
			max-width: 100%;
			border-radius: 0;
		}

		&-top {
			padding: $standardspacing;
			font-weight: 700;
			// font-size: $extraspacing;
			text-transform: uppercase;
			border-bottom: 0.1rem solid $backgroundgrey;
			align-items: center;
		}

		&-content {
			padding: $standardspacing;

			&.nopadding {
				padding: 0;
			}

			.generatedlink {
				margin-top: 1rem;
				background: $backgroundgrey;
				border-radius: 0.25rem;
				padding: 1rem;
				color: $black;
				border: 0.1rem dashed $bordergrey;

				a {
					text-decoration: none;
					color: $black;
				}

				&-copy {
					display: flex;
					justify-content: flex-end;
				}
			}
		}

		&.extrawidth {
			min-width: 85%;
			max-width: 95%;
		}
	}
}
