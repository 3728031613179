.logoloader {
	&-container {
		width: 100%;
		z-index: 10000;
		background: $white;
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		color: $primaryblue;
		justify-content: center;
		font-size: 2.5rem;
		font-weight: 700;
		font-family: 'Spartan', sans-serif;
		flex-flow: column;
		border-top: 0.5 * $standardspacing solid #059797;

		.bottomborder {
			height: 0.5 * $standardspacing;
			background: $primaryblue;
			border-radius: 2.5rem;
			width: 5rem;
			position: relative;

			@keyframes fillup {
				0% {
					width: 0;
				}

				50% {
					width: 2.5rem;
				}

				100% {
					width: 5rem;
				}
			}

			&::before {
				content: attr(data-text);
				height: 0.5 * $standardspacing;
				position: absolute;
				top: 0;
				left: 0;

				background-color: #059797;
				overflow: hidden;

				width: 0%;
				border-radius: 2.5rem;
				transition: 7s;
				animation: fillup 7s linear infinite;
				-moz-animation: fillup 7s linear infinite;
				-o-animation: fillup 7s linear infinite;
				-webkit-animation: fillup 7s linear infinite;
			}
		}
	}

	&-text {
		display: flex;
		align-items: center;
	}

	&-two {
		position: relative;
		display: inline-block;
		margin-left: 0.35 * $standardspacing;

		@keyframes fillup {
			0% {
				width: 0;
			}

			50% {
				width: 50%;
			}

			100% {
				width: 100%;
			}
		}

		&::before {
			content: attr(data-text);
			position: absolute;
			top: 0;
			left: 0;

			color: #059797;
			overflow: hidden;

			width: 0%;
			transition: 1.5s;
			animation: fillup 1.5s linear infinite;
			-moz-animation: fillup 1.5s linear infinite;
			-o-animation: fillup 1.5s linear infinite;
			-webkit-animation: fillup 1.5s linear infinite;
		}
	}
}
