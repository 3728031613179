.dashboard {
	&-navbar {
		@media print {
			display: none;
		}

		width: 100%;
		border-bottom: 0.1rem solid $backgroundgrey;

		.nopadding {
			padding: 0 0.5rem;
			overflow-x: auto;
			white-space: nowrap;
			text-align: left;
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */

			&::-webkit-scrollbar {
				display: none;
			}
		}

		.tabswitcher {
			display: inline-block;
			padding: $standardspacing;
			transition: 0.75s;
			text-decoration: none;
			color: $black;
			font-weight: 500;
			font-size: 0.925rem;
			border-bottom: 0.15rem solid $white;

			.MuiSvgIcon-root {
				font-size: 1.25rem;
			}

			&.active {
				color: $primary;
				border-bottom: 0.15rem solid $primary;
			}
		}

		&-tabsContainer {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;

			&-profile {
				flex: 1;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-end;

				&-box {
					background-color: white;
					box-shadow: $standardshadow;
					padding: 0.75em;
					transform: translateX(-1.5em);
				}
			}
		}
	}

	&-top {
		display: flex;
		align-items: center;

		&-heading {
			font-size: 1.35rem;
			font-weight: 600;
			letter-spacing: 0.075rem;
			text-transform: uppercase;
		}

		&-options {
			display: flex;
			justify-content: flex-end;
		}
	}
}
