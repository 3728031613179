.ticketmessage {
	border-radius: $standardspacing;
	border: 0.1rem solid $backgroundgrey;
	max-width: 75%;
	box-shadow: $wideshadow;
    background-color: $white;
	overflow: hidden;

	a {
		text-decoration: none;
        margin-bottom: 0.5 * $standardspacing;
        font-size: 0.925 * $extraspacing;
        display: block;
	}

	&-container {
		display: flex;
		width: 100%;
		margin-bottom: $standardspacing;

		&.left {
			text-align: left;
			justify-content: flex-start;
		}

		&.right {
			text-align: right;
			justify-content: flex-end;
		}
	}

	&-message {
		font-size: $extraspacing;
		margin-bottom: 0.25 * $extraspacing;
        white-space: pre-wrap;
	}

	&-image{
		max-height: 150px;
		object-fit: cover;

		&-container {
			max-height: 150px;
			overflow: hidden;
			display: block;
		}
	}

	&-content {
		padding: $standardspacing;
	}

	&-time {
		font-size: 0.875 * $standardspacing;
		color: $textgrey;

		.MuiSvgIcon-root {
			font-size: $midspacing;
		}
	}

	&.opposite {
		color: $white;
		background: rgb(32, 154, 202);
		border: none;

		.ticketmessage-time,
		a {
			color: $white;
		}
	}
}
