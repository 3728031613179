// General Navigation Bar
.general-navbar {
	background: $white;
	border-bottom: 0.1rem solid $backgroundgrey;

	a,
	&-tabswitcher {
		display: inline-block;
		padding: $standardspacing;
		transition: 0.75s;
		text-decoration: none;
		color: $black;
		font-weight: 500;
		font-size: 0.925rem;
		border-bottom: 0.15rem solid $white;

		.MuiSvgIcon-root {
			font-size: 1.25rem;
		}

		&.active {
			color: $primary;
			border-bottom: 0.15rem solid $primary;
		}
	}

	.nopadding {
		padding: 0;
		overflow-x: auto;
		white-space: nowrap;
		text-align: left;
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */

		&::-webkit-scrollbar {
			display: none;
		}
	}
}
