.sidedock {
	transition: 0.3s;
	height: 100vh;
	width: 0;
	overflow: hidden;
	position: fixed;
	right: 0;
	top: 0;
	bottom: 0;

	&-top {
		padding: $extraspacing;
		font-weight: 600;
		letter-spacing: 0.0125rem;
		border-bottom: 0.075rem solid $backgroundgrey;
		font-size: 1.075rem;
		text-transform: uppercase;
	}

	&-content {
		padding: $standardspacing;
		max-height: 87.5%;
		overflow-y: auto;
	}

	&.active {
		width: 500px;
		background: $white;

		&.fullwidth {
			width: 100%;
		}

		@media (max-width: 576px) {
			width: 100%;
		}
	}

	&-container {
		transition: 0.3s;
		overflow: hidden;

		&.active {
			position: fixed;
			background: rgba(0, 0, 0, 0.7);
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			z-index: 999;

			@media only screen and (max-width: 768px) {
				padding: 0;
			}
			height: auto;
			overflow-y: auto;
		}
	}
}
