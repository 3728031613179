.ticketcard {
	padding: $standardspacing;
	border-radius: 0.5 * $standardspacing;
	border: 0.075rem solid $backgroundgrey;
	display: block;
	margin-bottom: $extraspacing;
	text-align: left;
	color: $black;
	border-left: 0.125rem solid $closedred;

	&:focus,
	&:hover,
	&:active {
		text-decoration: none;
	}

	&:hover {
		box-shadow: $wideshadow;
	}

	&.active {
		border-left-color: $activegreen;
	}

	&.closed {
		border-left-color: $closedred;
	}

	&-title {
		font-weight: 500;
		font-size: 1.125 * $extraspacing;
		text-transform: uppercase;
		letter-spacing: 0.025rem;
	}

	&-desc {
		color: $textgrey;
		font-size: 0.925 * $extraspacing;
		margin: 0.25 * $extraspacing 0;
	}

    &-maindetails {
        width: 85%;
        padding: 0 $standardspacing;
    }

    &-extradetails {
        font-size: $midspacing;
        color: $textgrey;
    }

    &-status {
        border-radius: 2.5 * $extraspacing;
        background-color: $closedred;
        color: $white;
        padding: 0.25 * $extraspacing $extraspacing;
        display: inline-block;
        text-transform: capitalize;

        &.active {
            background-color: $activegreen;
        }

        &.closed {
            background-color: $closedred;
        }
    }

	&-image {
		border-radius: 50%;
		height: 3.5rem;
		width: 3.5rem;

		&-container {
			display: flex;
			height: 100%;
			align-items: center;
			justify-content: center;
			padding: 0.25rem;
			height: 4rem;
			width: 4rem;
		}
	}
}
