.dropzoneclass {
	padding: 0.65rem;
	display: flex;
	flex-flow: column;
	justify-content: center;
    border-radius: $standardspacing;
    font-size: $extraspacing;
    color: $textgrey;

    .MuiDropzoneArea-icon {
        fill: $textgrey;
    }
}
