$white: #ffffff;
$black: #191919;
$primaryblue: #1d69b6;
$brandingbase: #1d69b6;
$primary: $primaryblue;

$textgrey: #607d8b;
$textblue: #263238;
$darkertextgrey: #7d7e8c;
$backgroundgrey: #efefef;
$bordergrey: #cfcfcf;
$hovergrey: #afafaf;
$textgrey: #707070;
$subtextgrey: #909090;
$crmgrey: #f1f3f5;
$extragrey: #c4c4c4;

$crmblue: #172b4d;

// Box Shadows
$standardshadow: 0 1px 2px 0.25px rgba(0, 0, 0, 0.12), 0 1px 3px 0.25px rgba(0, 0, 0, 0.24);
$longershadow: 0 5px 10px 0.25px rgba(0, 0, 0, 0.12), 0 5px 15px 0.25px rgba(0, 0, 0, 0.24);
$wideshadow: 0 5px 40px rgba(50, 50, 93, 0.1);
$darkerwideshadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
$funkyshadow: 0 2px 5px 0px rgba(50, 50, 93, 0.1);
$materialshadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
	0px 1px 5px 0px rgba(0, 0, 0, 0.12);
$blueshadow: 0px 1px 10px rgb(45, 156, 219);
$innerboxshadow: inset 0px -50px 20px -30px rgba(0, 0, 0, 0.12);

// Paddings and Spacing
$standardspacing: 0.75rem;
$midspacing: 0.875rem;
$extraspacing: 1rem;

$completedgreen: #1ec268;
$completedgreenaccent: #e1f6f6;

:root {
	--themecolor: #12b484;
	--secondarycolor: #25c0b7;
	--paymenttext: #3c5b59;
	--expandedblue: #6485ff;
	--expandedblueaccent: #819aff;
}

// Project Management Variables
$todogrey: $backgroundgrey;
$inprogress: #2490ef;
$ganttblue: $inprogress;
$milestonepurple: rgb(105, 8, 216);
$completed: #1ea362;
$completedbackground: #32d687;
$finished: #49b882;
$delayed: #f13535;
$taskgrey: #a5afb9;
$taskblue: #57dbd8;
$lightred: #f5a0a0;

// Ticket Statuses
$activegreen: $completed;
$closedred: $delayed;
