.photoselector {
	cursor: pointer;
	padding: $extraspacing;
	border-radius: $standardspacing;
	border: 0.0875rem dashed $bordergrey;
	background: $backgroundgrey;
	width: 7.5 * $extraspacing;
	height: 7.5 * $extraspacing;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: $standardspacing auto;

	&-label {
		color: #607d8b;
		font-size: $midspacing;
	}
}
