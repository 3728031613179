.logout-floater,
.logout-floater:hover,
.logout-floater:focus {
	background: $white;
	border-radius: 50%;
	position: fixed;
	top: 1rem;
	right: 1rem;
	height: 3rem;
	width: 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $black;
	z-index: 10000;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
