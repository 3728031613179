.downloadcard {
	background: $white;
	box-shadow: $wideshadow;
	border-radius: 0.75rem;
	padding: 1rem 1.5rem;
	color: $black;
	text-decoration: none;
	max-width: 420px;
    @media screen and (min-width: 576px) {
        min-width: 380px;
    }
	display: block;
	margin: 1.5rem auto;
	text-align: center;
	filter: drop-shadow(0px 24px 48px rgba(176, 190, 197, 0.32)),
		drop-shadow(0px 3.38629px 5.64383px rgba(176, 190, 197, 0.32));
    font-size: 1.35rem;
    text-transform: capitalize;
    font-weight: 600;
    letter-spacing: 0.5px;
    border: 0.1rem solid #efefef;

	&:hover,
	&:focus {
		text-decoration: none;
		color: $black;
	}

	.MuiSvgIcon-root {
		fill: #1857ca;
	}

    &-heading {
        &-title {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    &-image {
        max-width: 100%;
        max-height: 11.5rem;

        &-container {
            margin-top: 1.5rem;
            width: 95%;
            max-height: 12.5rem;
            margin-left: auto;
            margin-right: auto;
            padding: 1rem;
            text-align: center;
        }
    }
}
