.removableimage {
	margin: $standardspacing auto;
	position: relative;
	overflow: hidden;
	height: 7.5rem;
	width: 7.5rem;
	border-radius: 0.925rem;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #b1aeae;
	cursor: pointer;
	border: 0.1rem solid $bordergrey;

	.MuiSvgIcon-root.videoicon {
		font-size: 3.5 * $extraspacing;
	}
}

/* Preview */
.removableimage-remover,
.removableimage-option {
	position: absolute;
	background: transparent;
	display: flex;
	top: 0;
	right: 0;
	height: 2.75rem;
	width: 2.75rem;
	padding: 0.75rem;
	opacity: 1;
	transition: 0.3s;
	justify-content: center;
	align-items: center;
}

.removableimage-remover .MuiSvgIcon-root,
.removableimage-option .MuiSvgIcon-root {
	background: var(--danger);
	fill: $white;
	border-radius: 50%;
	padding: 0.25rem;
}

.removableimage-remover:hover,
.removableimage-option:hover {
	cursor: pointer;
	opacity: 1;
}

.removableimage .removableimage-image {
	height: 7.5rem;
	width: 7.5rem;
	object-fit: cover;
}