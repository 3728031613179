.carousel {
	margin: 3 * $extraspacing 0;
}

.custom {
	&-slide {
		* {
			border-radius: 1rem;
			overflow: hidden;
		}
	}

	&-pagination {
		bottom: -30px !important;
	}

	&-dots {
		background-color: #cfcfcf !important;

		&.is-active {
			background-color: var(--secondarycolor) !important;
			opacity: 1 !important;
		}
	}
}
