.multiplefileinput {
	cursor: pointer;
	color: #607d8b;
	padding: $extraspacing;
	border-radius: 0.5 * $standardspacing;
	text-align: center;
	font-size: $extraspacing;
    background: $white;
    border: 0.1rem solid $backgroundgrey;
    box-shadow: $wideshadow;

	&-previews {
		&-image {
			max-width: 5rem;
			border: 0.1rem solid $backgroundgrey;
		}
	}
}
