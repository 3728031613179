.reusable-maps-selector {
	max-width: 450px;
	margin: 0 auto;
	overflow: hidden;

	&-map {
		height: 500px;
		min-width: 450px;
		max-width: 450px;
	}
	#searchinput {
		background-color: #fff;
		font-size: 0.925rem;
		border-radius: 0.33rem;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
		font-weight: 300;
		margin-left: 0.75rem;
		text-overflow: ellipsis;
		width: 75%;
		padding: 0.5rem;
		margin-top: 0.5rem;
		border: 0.1rem solid #cfcfcf;
		outline: none;
	}

	#searchinput:focus {
		border-color: #4d90fe;
	}

	&-locationlabel {
		font-size: $midspacing;
	}

	&-locationtype {
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;

		a,
		&-selector {
			border-radius: 2rem;
			padding: 0.35 * $extraspacing $standardspacing;
			font-size: $standardspacing;
			border: 0.075rem solid var(--themecolor);
			color: var(--themecolor);
			display: inline-flex;
			align-items: center;
			justify-content: center;
			max-width: 5rem;
			text-decoration: none;
			font-weight: 500;
			margin: 0 $standardspacing;
			transition: 0.7s;

			.MuiSvgRoot-icon {
				margin-left: 0.25 * $extraspacing;
			}

			&.active {
				background: var(--themecolor);
				color: $white;
			}
		}
	}
}

.pac-container {
	z-index: 1000000 !important;
}
