.resvideo {
	height: 100%;
	width: 425px;
	box-shadow: $wideshadow;
	border-radius: 0.75rem;
	border: 0.1rem solid $backgroundgrey;
	margin-bottom: 1.5rem;

	@media screen and (max-width: 576px) {
		width: auto;
		max-width: 100%;
		height: auto;
	}
}
