.teammembertile {
	margin-bottom: 0.75rem;
	box-shadow: $funkyshadow;
	border: 0.1rem solid $backgroundgrey;
	border-left: 0.15rem solid $primary;
	padding: 1rem 0.75rem;
	display: flex;
	align-items: center;
	border-radius: 0.25rem;

	&-image {
		max-width: 2.5rem;
		max-height: 2.5rem;
		border-radius: 50%;
		border: 0.1rem solid $backgroundgrey;
	}

	.accountcircle {
		max-width: 2.5rem;
		overflow: hidden;
		.MuiSvgIcon-root {
			font-size: 2.5rem;
		}
	}

	.maindetails {
		padding-left: 0.5rem;

		.membername {
			font-weight: 500;
			font-size: 1.125rem;
		}

		.memberemail {
			font-weight: 400;
			font-size: 0.875rem;
			color: $textgrey;
		}

		.memberposition {
			font-weight: 500;
			color: $textgrey;
			font-size: 0.75rem;
		}
	}

	.positioncol {
		text-align: right;
		text-transform: capitalize;

		.youlabel {
			color: teal;
		}
	}
}
