body {
	font-family: 'Roboto', sans-serif;
	overflow-x: hidden;
	border-top: 0.25rem solid var(--themecolor);
}

html {
	overflow-x: hidden;
}

.fixedcontainer {
	max-width: 1100px;
	padding: $standardspacing;
	margin: 0 auto;
}

.elevatedinput {
	box-shadow: $wideshadow;
	outline: none;
	height: auto;
	width: 100%;
	border-radius: 0.5 * $standardspacing;
	padding: $standardspacing;
	border: 0.075rem solid $bordergrey;
}

.primarythemesvg {
	fill: var(--themecolor);
}

.nonefound {
	padding: 1.5rem 0;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	min-height: 40vh;

	.MuiSvgIcon-root {
		font-size: 5rem;
	}
}

.floatingbutton-container {
	position: fixed;
	right: 1.5rem;
	bottom: 1.5rem;
	z-index: 5;
}

.statuslabel {
	padding: 0.5 * $standardspacing $extraspacing;
	min-width: 6.5 * $extraspacing;
	display: inline-block;
	border-radius: 5 * $extraspacing;
	color: $textgrey;
	background-color: $backgroundgrey;

	&.completedstatuslabel {
		color: $white;
		background: $completed;
	}

	&.inprogressstatuslabel {
		color: $white;
		background: $inprogress;
	}

	&.duestatuslabel {
		color: $white;
		background-color: $delayed;
	}
}
