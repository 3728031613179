.login-container {
	-webkit-font-smoothing: antialiased;
	font-family: 'IBM Plex Sans', sans-serif;
	.inner-container {
		.logo {
			width: 200px;
			margin: 20px;
		}
		.background {
			width: 100vw;
		}

		.login-form {
			display: flex;
			flex-direction: column;
			width: 100%;

			.header-text {
				font-size: 27px;
				font-weight: 500;
				line-height: 46px;
				text-transform: capitalize;
				padding-inline: 0.5rem;
				color: #2a2e34;
				text-align: center;
			}

			.input-group {
				padding-inline: 20px;
				flex-direction: column;
				.input-container {
					border: 1px solid #d6d9de;
					border-radius: 9px;
					height: 50px;
					width: 100%;
					font-size: 14px;
					box-sizing: border-box;
					padding: 0 10px;
					&:focus-within {
						border-width: 1.8px;
						transition: 100ms;
						border-color: #03989e;
					}
					.icon {
						display: flex;
						align-items: center;
						padding: 0 5px;
						svg {
							width: 18px;
							height: 18px;
							color: #b9bec7;
						}
					}

					.input-adornment {
						display: flex;
						color: #b9bec7;
						align-items: center;
					}

					input {
						flex: 1;
						border: none;
						outline: none;
						font-size: 14px;
						font-weight: 400;
						line-height: 1;
						height: 100%;
						color: #2a2e34;
						::-moz-placeholder {
							font-size: 11px;
							color: #b9bec7;
						}
					}
					input:disabled {
							background-color:#ffff;
              color:#727479 ;
						}
					input:-webkit-autofill {
						-webkit-box-shadow: 0 0 0 30px white inset !important;
					}
					.show-password {
						display: flex;
						align-items: center;
						color: #b9bec7;
						cursor: pointer;
					}
				}
			}

			.submit-button {
				width: fit-content;
				font-size: 14px;
				padding-inline: 1.5rem;
				margin-block: 22px;
				margin-left: auto;
				margin-right: auto;
				display: block;
				--tw-shadow-colored: 0 25px 50px -12px #03989e;
				box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
					var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow-colored);
			}

			.bottom-text {
				cursor: 'pointer';
				margin-top: 5px;
				font-size: 12px;
			}
		}
	}
}

.login-page-container {
	width: 350px;
	border: 1px solid rgb(236, 240, 241);
	padding: 20px 25px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
